const colors = {
  primary: '#FFEEAA',
  primary900: '#FF816E',
  primary800: '#FF9378',
  primary700: '#FFA582',
  primary600: '#FFB88C',
  primary500: '#FFCA96',
  primary400: '#FFDCA0',
  primary200: '#FFF1C1',
  primary100: '#FFF5D7',
  primary50: '#FFF8EE',

  secondary: '#51E9C9',
  secondary900: '#96BCE0',
  secondary800: '#8BC3DC',
  secondary700: '#7FCBD8',
  secondary600: '#74D2D4',
  secondary500: '#68DAD0',
  secondary400: '#5DE1CD',
  secondary200: '#46F0C5',
  secondary100: '#59FFCF',
  secondary50: '#92FFDC',

  gray900: '#252729',
  gray800: '#46494B',
  gray700: '#66686B',
  gray600: '#7A7D7F',
  gray500: '#A4A6A9',
  gray400: '#C2C4C8',
  gray300: '#E4E7EA',
  gray200: '#EFF2F5',
  gray100: '#F4F7FA',
  gray50: '#F9FBFF',

  strong: '#171717',
  normal: 'rgba(23,23,23,0.87)',
  assistive: 'rgba(23,23,23,0.7)',
  supportive: 'rgba(23,23,23,0.4)',
  disabled: 'rgba(23,23,23,0.15)',

  alert: '#F14841',
  alertHover: '#CF2922',
  alertFocus: '#C62721',

  check: '#28BFC2',

  warning: '#FBB54A',
  success: '#3CCB39',

  dimSoft: 'rgba(0,0,0,0.4)',
  dimHard: 'rgba(0,0,0,0.6)',

  fill020: 'rgba(23,23,23,0.2)',
  fill012: 'rgba(23,23,23,0.12)',
  fill005: 'rgba(23,23,23,0.05)',
  fill003: 'rgba(23,23,23,0.03)',
  fill002: 'rgba(23,23,23,0.02)',

  gradient: `linear-gradient(#2FFFBD, #96BCE0)`,
};

const device = {
  sMobile: 'only screen and (max-width: 380px)',
  mobile: 'only screen and (max-width: 767px)',
  tablet: 'only screen and (max-width: 1023px)',
  desktop: 'only screen and (min-width: 1024px)',
  ratio: `only screen and (max-width: 1280px) and (min-aspect-ratio: 16/9),
  screen and (max-width: 1900px) and (min-aspect-ratio: 2/1)`,
};

export const theme = {
  colors,
  device,
};
