import React, { useState } from 'react';
import { Text } from 'styles';
import { Icon } from 'components/icon/Icon';
import * as S from './InputStyle';

export function FormikInput({ onChange, onBlur, disabled, touched, invalid, type, clearValue, ...props }) {
  return (
    <div>
      <S.InputBox>
        <S.Input
          id={props.name}
          name={props.name}
          value={props.value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={props.placeholder}
          $invalid={touched && invalid && invalid.length > 0}
          disabled={disabled}
          type={type}
        />
        {(touched && invalid && invalid.length > 0) && (<Icon name="Warning" />)}
        {(!invalid || invalid.length <= 0) && props.value && clearValue && (<Icon name="Cancel" onClick={() => clearValue()} />)}
      </S.InputBox>
      {touched && invalid && invalid.length > 0 && (
        <Text $type="14" $color="alert">
          {invalid}
        </Text>
      )}
    </div>
  );
}
