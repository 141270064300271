import React from 'react';
import { Box, RowBox, Text } from 'styles';
import { Icon } from 'components/icon/Icon';
import { Button } from 'components/button/Button';
import * as S from './ConfirmStyle';
import { Dim } from '../Dim';

export function Confirm({ confirm, setConfirm, ...props }) {
  return (
    <Dim open={confirm}>
      <S.ConfirmContainer>
        <RowBox $justifyContent="space-between" $padding="0 10px 6px">
          <Text $type="b16">{props.title || '알림'}</Text>
          {props.close && <Icon name="Close" onClick={() => setConfirm(false)} />}
        </RowBox>
        <Box $padding="10px" $marginBottom={10} $borderTop>
          <Text $type="m14">{props.content}</Text>
        </Box>
        <RowBox $gap={6} $justifyContent="flex-end">
          <Button $type="cancel" onClick={() => setConfirm(false)}>
            취소
          </Button>
          <Button $type="modal" onClick={props.onClick}>
            확인
          </Button>
        </RowBox>
      </S.ConfirmContainer>
    </Dim>
  );
}
