import React from 'react';
import { Text } from 'styles';
import * as S from './RadioStyle';

export function Radio({ name, value, onChange, label, children, checked, ...props }) {
  const handleOnChange = () => {
    if (onChange) {
      onChange(name, value);
    }
  };
  return (
    <S.Label htmlFor={`${name}_${value}`} {...props}>
      <S.Radio id={`${name}_${value}`} name={name} value={value} checked={checked} onChange={handleOnChange} />
      {children}
    </S.Label>
  );
}
