import React from 'react';
import { Img } from 'components';
import { H2, Section, Box } from 'styles';

export function Section5(props) {
  return (
    <Section $backgroundColor="gray50">
      <H2 $display="none">서비스 이용안내</H2>
      <Box $margin="auto" $width="fit-content" $padding="128px 40px 152px" $mPadding="64px 10px" $maxWidth={1600}>
        <Img desktopSrc="main4" mobileSrc="moMain4" $width="100%" />
      </Box>
    </Section>
  );
}
