import React from 'react';
import { Box, RowBox, Text } from 'styles';
import { Icon } from 'components';
import * as S from './FooterStyle';

export function Footer(props) {
  return (
    <S.Footer>
      <Box $maxWidth={1600} $padding="40px" $mPadding="36px" $margin="auto">
        <Icon name="LogoText" />
        <Box $padding="24px 40px" $mPadding="24px 0 24px 40px">
          <RowBox $gap={8}>
            <Text $color="supportive" $type="m12" $whiteSpace="pre">
              상호명
            </Text>
            <Text $color="assistive" $type="m12">
              나모웹비즈 주식회사
            </Text>
          </RowBox>
          <RowBox $gap={8} $marginTop={2}>
            <Text $color="supportive" $type="m12" $whiteSpace="pre">
              사업자등록번호
            </Text>
            <Text $color="assistive" $type="m12">
              215-87-91517
            </Text>
          </RowBox>
          <RowBox $gap={8} $marginTop={2}>
            <Text $color="supportive" $type="m12" $whiteSpace="pre">
              대표
            </Text>
            <Text $color="assistive" $type="m12">
              진병각
            </Text>
          </RowBox>
          <RowBox $gap={8} $marginTop={2}>
            <Text $color="supportive" $type="m12" $whiteSpace="pre">
              주소
            </Text>
            <Text $color="assistive" $type="m12">
              서울특별시 송파구 법원로11길 11 B동 1403호
            </Text>
          </RowBox>
        </Box>
        <Text $color="fill012" $type="m11">
          Copyright Namowebiz,Inc. All Rights Reserved.
        </Text>
      </Box>
    </S.Footer>
  );
}
