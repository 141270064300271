import styled from 'styled-components';
import { border, common, flex, grid, space } from 'styles/css';

export const List = styled.ul`
  ${common}
  ${space}
  ${border}
`;

export const FlexList = styled(List)`
  display: flex;
  ${flex}
`;

export const GridList = styled(List)`
  display: grid;
  ${flex}
  ${grid}
`;

export const ListItem = styled.li`
  ${common}
  ${space}
  ${border}
`;

export const FlexListItem = styled(ListItem)`
  display: flex;
  ${flex}
`;
