import styled, { css } from 'styled-components';
import { common, space } from 'styles/css';

export const CustomBtn = styled.button`
  ${common}
  ${space}
  font-size:14px;
  white-space: pre;

  ${({ $type }) =>
    $type === 'text'
      ? css`
          background-color: transparent;
        `
      : $type === 'line'
        ? css`
            border: 1px solid ${({ theme }) => theme.colors.gray400};
            border-radius: 8px;
            background-color: white;
            color: ${({ theme }) => theme.colors.gray400};
            padding: 8px 24px 8px 16px;
          `
        : $type === 'modal'
          ? css`
              border-radius: 4px;
              padding: 8px 14px;
              font-weight: 500;
              transition: all 150ms linear;
              color: white;
              background-color: ${({ theme }) => theme.colors.primary700};
            `
          : $type === 'cancel'
            ? css`
                border-radius: 4px;
                padding: 8px 14px;
                font-weight: 500;
                transition: all 150ms linear;
                background-color: white;
                border: 1px solid ${({ theme }) => theme.colors.gray400};
                color: ${({ theme }) => theme.colors.gray400};
              `
            : css`
                border-radius: 10px;
                padding: 17px 24px;
                font-weight: 500;
                transition: all 150ms linear;
                color: white;
                background-color: ${({ theme }) => theme.colors.primary700};
              `}

  &:disabled {
    pointer-events: none;
  }
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
    }
  }
`;
