import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
* {  
  margin: 0;
  padding: 0;
  border: 0;
  padding-block: 0;
  padding-inline: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  vertical-align: baseline;
  -webkit-tap-highlight-color: transparent;
  box-sizing:border-box;
}

body {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-size: 16px;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  height: 100%;
  background-color: white;
  

}

a {
  display: inline-block;
  text-decoration: none;
  color: initial;
}

ol, ul, li{
  list-style-type: none;
}

textarea{
  outline:none; 
  font-size:16px;
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

button {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-size:16px;
  line-height: 1;
}

input {
  font-family: "Pretendard Variable", Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  box-sizing:border-box;
  outline:none;
}



::-webkit-scrollbar {
  width: 6px;
  height:6px;
}

::-webkit-scrollbar-thumb {
background-color:${({ theme }) => theme.colors.gray500};
  border-radius: 4px; 
}

`;
