import styled from 'styled-components';
import { space } from 'styles/css';

export const ImgContainer = styled.img`
  ${space}
  ${({ onClick }) => onClick && `cursor:pointer`};
  object-fit: ${({ $objectFit }) => $objectFit || 'contain'};
  ${({ $transform }) => $transform && `transform:${$transform}`};
  ${({ $objectPosition }) => $objectPosition && `object-position: ${$objectPosition}`};

  @media ${({ theme }) => theme.device.tablet} {
    ${({ $tObjectFit }) => $tObjectFit && `object-fit: ${$tObjectFit}`};
    ${({ $tTransform }) => $tTransform && `transform:${$tTransform}`};
    ${({ $tObjectPosition }) => $tObjectPosition && `object-position: ${$tObjectPosition}`};
  }

  @media ${({ theme }) => theme.device.mobile} {
    ${({ $mObjectFit }) => $mObjectFit && `object-fit: ${$mObjectFit}`};
    ${({ $mTransform }) => $mTransform && `transform:${$mTransform}`};
    ${({ $mObjectPosition }) => $mObjectPosition && `object-position: ${$mObjectPosition}`};
  }
`;
