import React from 'react';
import { Box, FlexList, Text } from 'styles';
import { Button } from 'components/button/Button';
import * as S from './FileBoxStyle';

export function FileBox({ list }) {
  return (
    <FlexList $marginTop={8} $gap={8} $mFlexDirection="column">
      {list.map((item, index) => (
        <S.FileItem key={`item_${index}`} $border={{ color: 'gray300' }}>
          <Box $width="100%">
            <Text $color="gray600" $mType="14">
              {item.fileName}
            </Text>
            <Text $color="gray400" $mType="11">
              {item.capacity}
            </Text>
          </Box>
          <Button $type="text">
            <Text $color="gray500" $type="m11" $padding="10px">
              삭제
            </Text>
          </Button>
        </S.FileItem>
      ))}
    </FlexList>
  );
}
