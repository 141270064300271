import React from 'react';
import { Img } from 'components';
import { H2, Section } from 'styles';

export function Section6({ setRef }) {
  return (
    <Section
      ref={setRef}
      $margin="auto"
      $width="fit-content"
      $padding="40px"
      $mPadding="64px 10px"
      $maxWidth={1600}
      $scrollMarginTop={91}
      $mScrollMarginTop={107}
    >
      <H2 $display="none">가격 안내</H2>
      <Img desktopSrc="main5" mobileSrc="moMain5" $width="100%" />
    </Section>
  );
}
