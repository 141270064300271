import httpRequest from "./http-request";

const sendInquiry = async (formData) => {
  const response = await httpRequest.requestMultipart('/sendInquiry', {}, formData);

  return response;
}

export default {
  sendInquiry
}