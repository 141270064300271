import React from 'react';
import { Img } from 'components';
import { H2, Section, Box, Text, Span } from 'styles';

export function Section3({ setRef }) {
  return (
    <Section $backgroundColor="gray50" ref={setRef} $scrollMarginTop={91} $mScrollMarginTop={107}>
      <H2 $display="none">N-Survey란?</H2>
      <Box $maxWidth={1600} $padding="128px 40px" $mPadding="64px 20px" $margin="auto">
        <Text $type="24" $mType="18" $textAlign="center">
          저렴한 가격에, 빠른 설문조사로 2~3일 내에
          <br />
          <Span $type="b24" $mType="b18">
            고품질 데이터를 제공해 드리는 앤써베이
          </Span>
        </Text>
        <Text $type="b44" $mType="b24" $padding="24px 0" $textAlign="center">
          이런 분들께 추천드립니다!
        </Text>
        <Box $margin="auto" $width="fit-content">
          <Img desktopSrc="main2" mobileSrc="moMain2" $width="100%" $objectFit="cover" />
        </Box>
      </Box>
    </Section>
  );
}
