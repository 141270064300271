import styled, { css } from "styled-components";
import { border, common, space, typography } from "styles/css";

const mix = css`
  ${border}
  ${typography}
  ${space}
  ${common}
`;

export const H1 = styled.h1`
  ${mix}
`;

export const H2 = styled.h2`
  ${mix}
`;

export const H3 = styled.h3`
  ${mix}
`;

export const H4 = styled.h4`
  ${mix}
`;

export const Text = styled.p`
  ${mix}
`;

export const Span = styled.span`
  display: inline-block;
  ${mix}
`;

export const Label = styled.label`
  ${mix}
`;
