import React from 'react';
import { Icon } from 'components';
import { Box, FlexList, H1, H2, H3, RowBox } from 'styles';
import * as S from './HeaderStyle';

export function Header({ sectionRefs }) {
  const scrollToSection = (section) => {
    sectionRefs.current[section].scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <S.Header>
      <Box $maxWidth={1600} $margin="auto" $padding="20px 40px" $mPadding="0 20px">
        <H1 $display="none">N-Survey</H1>
        <RowBox $gap={160} $mGap={8} $alignItems="center" $mFlexDirection="column">
          <Icon name="LogoText" $mSvgHeight={31} $mMargin="9px 0" />
          <nav>
            <H2 $display="none">네비게이션 메뉴</H2>
            <FlexList $gap={16} $mGap={4}>
              <S.HeaderListItem $padding="10px 16px" $mPadding="14.5px 12px" onClick={() => scrollToSection('about')}>
                <H3 $type="m14">N-Survey란?</H3>
              </S.HeaderListItem>
              <S.HeaderListItem $padding="10px 16px" $mPadding="14.5px 12px" onClick={() => scrollToSection('price')}>
                <H3 $type="m14">가격안내</H3>
              </S.HeaderListItem>
              <S.HeaderListItem $padding="10px 16px" $mPadding="14.5px 12px" onClick={() => scrollToSection('request')}>
                <H3 $type="m14">문의/의뢰하기</H3>
              </S.HeaderListItem>
            </FlexList>
          </nav>
        </RowBox>
      </Box>
    </S.Header>
  );
}
