import React from 'react';
import { Img } from 'components';
import { H2, Section } from 'styles';

export function Section2(props) {
  return (
    <Section $padding="40px" $mPadding="20px" $maxWidth={1600} $margin="auto" $lineHeight="0">
      <H2 $display="none">50% 할인 이벤트</H2>
      <Img desktopSrc="event" mobileSrc="moEvent" $width="100%" />
    </Section>
  );
}
