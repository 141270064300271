import styled, { css } from 'styled-components';
import { border, common, flex, grid, space } from 'styles/css';
import { slideUp } from 'styles/css/keyframes';

export const Box = styled.div`
  ${common}
  ${border}
  ${space}
`;

export const RowBox = styled(Box)`
  display: flex;
  ${flex}
`;

export const ColBox = styled(Box)`
  display: flex;
  flex-direction: column;
  ${flex}
`;

export const GridBox = styled(Box)`
  display: grid;
  ${grid}
  ${flex}
`;

export const Section = styled.section`
  ${common}
  ${border}
  ${space}
  ${flex}
`;

// modal 관련 css

export const ModalWrapper = styled(Box)`
  ${slideUp}
  background-color: ${({ $wrapBgColor }) => $wrapBgColor || 'white'};
  border-radius: 10px;
  max-height: ${({ $wrapHeight }) => $wrapHeight || '80%'};
  position: ${({ $wrapPosition }) => $wrapPosition || 'relative'};
  box-shadow: ${({ theme }) => theme.colors.boxShadow};

  ${(props) =>
    props.$wrapTop &&
    css`
      top: ${props.$wrapTop};
    `};

  ${(props) =>
    props.$wrapLeft &&
    css`
      left: ${props.$wrapLeft};
    `};
`;
