import React from 'react';
import { H2, Section, Box, Text } from 'styles';
import { Button, Icon } from 'components';
import * as S from './LandingStyle';

export function Section1({ sectionRefs }) {
  return (
    <Section $padding="109px 40px 146px" $mPadding="20px 20px 64px" $margin="auto" $maxWidth={1600}>
      <H2 $display="none">빠르게 얻는 인사이트 N Survey</H2>
      <S.VisualBox>
        <Box $mPadding="0 17px">
          <Icon name="Main1" $mSvgHeight={200} $mSvgWidth="100%" />
        </Box>
        <Box>
          <Text $type="b56" $mType="b38">
            빠르게 얻는 인사이트
            <br />N Survey
          </Text>
          <Text $type="20" $mType="18" $padding="16px 0 60px" $mPadding="16px 0 32px">
            SK텔레콤의 1천 6백만 회원을 대상으로
            <br />
            빠르고 신뢰성 있는 시장 반응을 확인하세요.
          </Text>
          <Button onClick={() => sectionRefs.current['request'].scrollIntoView({ behavior: 'smooth' })}>
            문의 / 의뢰하기
          </Button>
        </Box>
      </S.VisualBox>
    </Section>
  );
}
