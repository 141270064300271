import React from 'react';
import * as S from './ButtonStyle';

export function Button({ icon, children, $type, ...props }) {
  return (
    <S.CustomBtn $type={$type} onClick={props.onClick} {...props}>
      {children}
    </S.CustomBtn>
  );
}
