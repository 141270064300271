import styled from 'styled-components';
import { ListItem, RowBox } from 'styles';

export const Main = styled.main`
  padding-top: 91px;

  @media ${({ theme }) => theme.device.mobile} {
    padding-top: 107px;
  }
`;

export const VisualBox = styled(RowBox)`
  gap: 46px;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.device.tablet} {
    flex-direction: column;
  }

  @media ${({ theme }) => theme.device.mobile} {
    align-items: flex-start;
    gap: 16px;
  }
`;

export const GradientItem = styled(ListItem)`
  background-color: ${({ theme }) => theme.colors.gray50};
  border-radius: 8px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    background-image: ${({ $checked }) => ($checked ? 'linear-gradient(to right, #2fffbd, #96bce0)' : 'none')};
    border-radius: 8px;
    z-index: -1;
  }
`;
