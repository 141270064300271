import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Img } from 'components/img/Img';
import { Box, GridList, H2, List, ListItem, RowBox, Section, Span, Text } from 'styles';
import { Button, Checkbox, FileBox, Icon, InputLabel, Radio, FormikInput, FormikTextarea,
  Alert, Confirm
} from 'components';

import inquireService from 'services/inquire-service';
import { getFileSizeString } from 'utils';
import { Spinner } from 'components/Spinner';
import * as S from './LandingStyle';
import { Section1 } from './Section1';
import { Section2 } from './Section2';
import { Section3 } from './Section3';
import { Section4 } from './Section4';
import { Section5 } from './Section5';
import { Section6 } from './Section6';
import { Section7 } from './Section7';

export function Landing({ sectionRefs }) {
  const PACKAGES = [
    {value: '1', name: '초간단 Survey', desc: '10문항 이하'},
    {value: '2', name: '퀄리티 Survey', desc: '11~20문항'},
    {value: '3', name: '프라임 Survey', desc: '21~30문항'},
    {value: '4', name: '전문가 Survey', desc: '31~40문항'},
  ];

  const RESPONSES = [
    {value: '1', name: '응답수 500'},
    {value: '2', name: '응답수 1,000'},
  ]

  const { t } = useTranslation();

  const [file, setFile] = useState(null);
  const [showFileError, setShowFileError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showAgree1Error, setShowAgree1Error] = useState(false);
  const [alert, setAlert] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const schema = yup.object().shape({
    name: yup.string().required(t('validation.required.entry')),
    company: yup.string(),
    phone: yup.string()
      .matches(/^\d+$/, t('validation.numbers.only')),
    email: yup.string()
      .required(t('validation.required.entry'))
      .email(t('validation.valid.email.address')),
    introduce: yup.string(),
    question: yup.string()
      .required(t('validation.required.entry'))
  });

  const handleOnSubmit = async (values) => {
    console.log(values);

    // if (file === null) {
    //   setShowFileError(true);
    // } else {
    //   setShowFileError(false)
    // }

    // if (!formik.isValid || file === null) {
    //   return
    // }

    if (!agree.agree1) {
      setShowAgree1Error(true);
    } else {
      setShowAgree1Error(false);
    }

    if (!formik.isValid || !agree.agree1) {
      return
    }

    const doSubmit = async () => {
      let response = null;

      setIsSubmitting(true)

      try {
        const formData = new FormData();

        if (file && file.name) {
          formData.append("file",file,file.name);
        }

        formData.append("name", values.name);
        formData.append("company", values.company);
        formData.append("phone", values.phone);
        formData.append("email", values.email);
        formData.append("introduce", values.introduce);
        formData.append("question", values.question);
        formData.append("package", values.package);
        formData.append("response", values.response);

        const selectedPackage = PACKAGES.filter((p) => p.value === values.package);
        if (selectedPackage && selectedPackage.length === 1) {
          formData.append("packageName", `${selectedPackage[0].name} (${selectedPackage[0].desc})`);
        }

        const selectedResponse = RESPONSES.filter((p) => p.value === values.response);
        if (selectedResponse && selectedResponse.length === 1) {
          formData.append("responseName", `${selectedResponse[0].name}`);
        }

        response = await inquireService.sendInquiry(formData);

        console.log(response)

        if (response && response.success === true) {
          setAlert({content: t('inquiry.complete.message'), onClick: () => {
            setAlert()
          }});
          formik.resetForm();
          setAgree({
            agree1: false,
            agree2: false
          })
          setFile(null);
        } else {
          setAlert({content: t('common.error.server'), onClick: () => {
            setAlert()
          }});
        }
      } catch(e) {
        console.error(e);
        setShowErrorModal(true);
        setAlert({content: t('common.error.server'), onClick: () => {
          setAlert()
        }});
      }

      setIsSubmitting(false);
    }

    

    setConfirm({
      content: '문의/의뢰를 요청하시겠습니까?',
      onClick: () => {
        setConfirm(false);
        doSubmit();
      }
    });
  };
  
  const formik = useFormik({
    initialValues: {
      name: '',
      company: '',
      phone: '',
      email: '',
      introduce: '',
      question: '',
      package: null,
      response: null,
    },
    validationSchema: schema,
    onSubmit: handleOnSubmit
  });


  

  // 스크롤 이동
  const setRef = (name) => (element) => {
    sectionRefs.current[name] = element;
  };

  // 개인정보 동의
  const [agree, setAgree] = useState({
    agree1: false,
    agree2: false,
  });
  const agreeOnChange = (name, checked) => {
    setAgree({ ...agree, [name]: checked });
  };
  // 정보 이용 동의 전문보기
  const [views, setViews] = useState([false, false]);

  const toggleAgree = (index) => {
    const newViews = [...views];
    newViews[index] = !newViews[index];
    setViews(newViews);
  };

  return (
    <S.Main>
      <Section1 sectionRefs={sectionRefs} />
      <Section2 />
      <Section3 setRef={setRef('about')} />
      <Section4 />
      <Section5 />
      <Section6 setRef={setRef('price')} />
      <Section7 onClick={() => {
        window.Kakao.Channel.chat({
          channelPublicId: process.env.REACT_APP_KAKAO_CHAT_CHANNEL_ID // Use the ID included in the home URL of Kakao Talk Channel.
        });
      }} />
      <Section
        ref={setRef('request')}
        $padding="128px 40px"
        $mPadding="64px 20px"
        $maxWidth={1600}
        $margin="auto"
        $scrollMarginTop={91}
        $mScrollMarginTop={107}
      >
        <H2 $display="none">의뢰하기</H2>
        <Text $type="b44" $mType="b24">
          지금 바로 의뢰하기
        </Text>
        <Box $margin="64px 0" $mMargin="32px 0" $borderTop={{ color: 'gray500' }} $borderBottom={{ color: 'gray500' }}>
          <RowBox $padding="40px" $mPadding="16px 0" $gap={32} $mGap={16} $tFlexDirection="column">
            <Box $minWidth={360} $mMinWidth="100%">
              <Text $type="b20" $mType="b18">
                의뢰인 정보
              </Text>
              <Text $type="18" $mType="14" $marginTop={18} $mMarginTop={8}>
                설문을 의뢰하시는 의뢰인의 정보를
                <br />
                알려주세요.
              </Text>
            </Box>
            <Box $width="100%">
              <GridList $grid={2} $gap={32} $mGap={30} $width="100%" $mGrid={1}>
                <ListItem>
                  <InputLabel label="의뢰자 성명" htmlFor="name" $invalid={formik.touched.name && formik.errors.name && "error"} require />
                  <FormikInput
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    placeholder="성명을 입력해 주세요"
                    touched={formik.touched.name} invalid={formik.errors.name}
                    clearValue={() => {
                      formik.setFieldValue('name', '', true);
                    }}
                  />
                </ListItem>
                <ListItem>
                  <InputLabel label="의뢰자 소속" htmlFor="company" $invalid={formik.touched.company && formik.errors.company && "error"}/>
                  <FormikInput
                    name="company"
                    value={formik.values.company}
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    placeholder="소속을 입력해 주세요"
                    touched={formik.touched.company} invalid={formik.errors.company}
                    clearValue={() => {
                      formik.setFieldValue('company', '', true);
                    }}
                  />
                </ListItem>
                <ListItem>
                  <InputLabel label="연락처" htmlFor="tel" $invalid={formik.touched.phone && formik.errors.phone && "error"}/>
                  <FormikInput
                    type="tel"
                    name="phone" 
                    value={formik.values.phone}
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    placeholder="숫자만 입력해 주세요"
                    touched={formik.touched.phone} invalid={formik.errors.phone}
                    clearValue={() => {
                      formik.setFieldValue('phone', '', true);
                    }}
                  />
                </ListItem>
                <ListItem>
                  <InputLabel label="이메일 주소" htmlFor="email" $invalid={formik.touched.email && formik.errors.email && "error"} require />
                  <FormikInput
                    name="email" 
                    value={formik.values.email}
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    placeholder="이메일 주소를 입력해주세요"
                    touched={formik.touched.email} invalid={formik.errors.email}
                    clearValue={() => {
                      formik.setFieldValue('email', '', true);
                    }}
                  />
                </ListItem>
              </GridList>
              <Box $borderTop $padding="32px 24px 0" $mPadding="16px 0 24px" $marginTop={32}>
                <Box $lineHeight="0">
                  <RowBox $alignItems="center" $gap={8} $mFlexDirection="column">
                    <Checkbox $padding="6px 0" name="agree1" checked={agree.agree1} onChange={(name, checked) => {
                      setAgree({ ...agree, [name]: checked });
                      if (checked) {
                        setShowAgree1Error(false)
                      } else {
                        setShowAgree1Error(true);
                      }
                    }}>
                      <RowBox $mFlexDirection="column" $alignItems="center" $mAlignItems="flex-start">
                        <Text $type="m16" $color="assistive">
                          <Span $type="b16">(필수)&nbsp;</Span>상담 서비스를 제공하기 위한 개인정보 수집 및 이용에
                          동의합니다.&nbsp;
                        </Text>
                        <Text
                          $type="14"
                          $color="secondary900"
                          $textDecoration="underline"
                          $cursor="pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            toggleAgree(0);
                          }}
                        >
                          전문보기
                        </Text>
                        {
                          showAgree1Error && 
                          <Text $type="14" $color="alert">
                            {t('validation.required.entry')}
                          </Text>
                        }
                      </RowBox>
                    </Checkbox>
                  </RowBox>
                  {views[0] && (
                    <Box $marginLeft={38}>
                      <Text $color="supportive" $type="14">
                        1. 수집 항목
                        <br />
                        필수 : 성명, 이메일
                        <br />
                        2. 수집 목적 : 앤써베이 서비스 이용을 위한 상담
                        <br />
                        3. 보유 기간 : 견적 문의일로부터 6개월
                        <br />※ 상담 서비스 제공을 위한 개인정보 수집 및 이용에 동의하지 않은 경우 앤써베이 서비스
                        견적을 제공 받을 수 없게 됩니다.
                      </Text>
                    </Box>
                  )}
                </Box>
                <Box $lineHeight="0">
                  <RowBox $alignItems="center" $gap={8} $mFlexDirection="column">
                    <Checkbox name="agree2" checked={agree.agree2} $padding="6px 0" onChange={agreeOnChange}>
                      <RowBox $mFlexDirection="column" $alignItems="center" $mAlignItems="flex-start">
                        <Text $type="m16" $color="assistive">
                          (선택) 마케팅 활용을 위한 개인정보 수집 및 이용에 동의합니다.&nbsp;
                        </Text>
                        <Text
                          $type="14"
                          $color="secondary900"
                          $textDecoration="underline"
                          $cursor="pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            toggleAgree(1);
                          }}
                        >
                          전문보기
                        </Text>
                      </RowBox>
                    </Checkbox>
                  </RowBox>
                  {views[1] && (
                    <Box $marginLeft={38}>
                      <Text $color="supportive" $type="14">
                        1. 수집 항목
                        <br />
                        필수 : 성명, 이메일
                        <br />
                        2. 수집 목적 : 뉴스레터 등 발송 및 맞춤형 마케팅 정보 제공
                        <br />
                        3. 보유 기간 : 구독 취소 등 마케팅 활용에 대한 동의 철회 시점까지
                        <br />※ 마케팅 정보 활용을 위한 개인정보 수집 및 이용에 동의하지 않은 경우 앤써베이 관련 소식을
                        제공 받을 수 없게 됩니다.
                      </Text>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </RowBox>
          <RowBox
            $padding="40px"
            $mPadding="40px 0 16px"
            $gap={32}
            $mGap={16}
            $borderTop
            $marginTop={24}
            $mMarginTop="0"
            $tFlexDirection="column"
          >
            <Box $minWidth={360} $mMinWidth="100%">
              <Text $type="b20" $mType="b18">
                설문 정보
              </Text>
              <Text $type="18" $mType="14" $marginTop={18} $mMarginTop={8}>
                설문에 대한 정보를 알려주세요.
              </Text>
            </Box>
            <Box $width="100%">
              <List>
                <ListItem>
                  <InputLabel label="1. 설문에 대해 간략히 소개해 주세요." htmlFor="introduce" />
                  <FormikInput
                    name="introduce"
                    value={formik.values.introduce}
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    placeholder="설문에 대해 간략히 소개해 주세요."
                    touched={formik.touched.introduce} invalid={formik.errors.introduce}
                    clearValue={() => {
                      formik.setFieldValue('introduce', '', true);
                    }}
                  />
                </ListItem>
                <ListItem $marginTop={32}>
                  <InputLabel label="2. 이미 작성한 설문지가 있다면 첨부해 주세요." />
                  <Box>
                    <Button $type="line"onClick={() => {
                      document.getElementById('formFile').click();
                    }}>
                      <RowBox $alignItems="center">
                        <Icon name="Add" />
                        파일첨부
                      </RowBox>
                    </Button>
                    <input style={{display: 'none'}} type="file" id="formFile" onChange={(event) => {
                      if (event && event.target && event.target.files && event.target.files[0]) {
                        setFile(event.target.files[0]);
                        setShowFileError(false);
                      } else {
                        setFile(null)
                        setShowFileError(true);
                      }
                    }}/>
                  </Box>
                  { file &&
                    <FileBox
                      list={[
                        { fileName: file.name, capacity: getFileSizeString(file.size) },
                      ]}
                    />
                  }
                </ListItem>
                <ListItem $marginTop={32}>
                  <InputLabel label="3. 희망하는 설문 문항수를 선택해 주세요." />
                  <GridList $grid={4} $marginTop={17} $gap={16} $mGap={4} $mGrid={1}>
                    {
                      PACKAGES.map((pk) => (
                        <S.GradientItem $checked={formik.values.package === pk.value} key={pk.value} $padding="9px 24px">
                          <Radio name="package" value={pk.value} onChange={() => {
                                formik.setFieldValue('package', pk.value)
                              }} checked={formik.values.package === pk.value}>
                            <Box>
                              <Text $type="b18" $color="normal">
                                {pk.name}
                              </Text>
                              <Text $type="16" $color="assistive">
                                {pk.desc}
                              </Text>
                            </Box>
                          </Radio>
                        </S.GradientItem>
                      ))
                    }
                  </GridList>
                </ListItem>
                <ListItem $marginTop={32}>
                  <InputLabel label="4. 희망하는 보장 응답수를 선택해 주세요." />
                  <GridList $grid={4} $gap={16} $marginTop={17} $mGrid={1} $mGap={4}>
                    {
                      RESPONSES.map((resp) => (
                        <S.GradientItem $checked={formik.values.response === resp.value} key={resp.value} $padding="21px 24px">
                          <Radio
                            name="response"
                            value={resp.value}
                            onChange={() => {
                              formik.setFieldValue('response', resp.value)
                            }}
                            checked={formik.values.response === resp.value}
                          >
                            <Text $type="b18" $color="normal">
                              {resp.name}
                            </Text>
                          </Radio>
                        </S.GradientItem>
                      ))
                    }
                  </GridList>
                </ListItem>
                <ListItem $marginTop={32}>
                  <InputLabel label="5. 알고싶은 사항을 적어주세요." htmlFor="question" $invalid={formik.touched.question && formik.errors.question && "error"} require />
                  <FormikTextarea
                    name="question"
                    value={formik.values.question}
                    onChange={formik.handleChange} onBlur={formik.handleBlur}
                    placeholder="문의사항을 입력해 주세요."
                    touched={formik.touched.question} invalid={formik.errors.question}
                  />
                </ListItem>
              </List>
            </Box>
          </RowBox>
        </Box>
        <Box $width="fit-content" $margin="auto">
          { isSubmitting ?
            <Spinner/>
            :
            <Button onClick={() => {
              if (!agree.agree1) {
                setShowAgree1Error(true);
              } else {
                setShowAgree1Error(false);
              }
  
              formik.submitForm();
            }}>문의 / 의뢰하기</Button>
          }
        </Box>
      </Section>
      <Section $padding="128px 20px" $mPadding="64px 20px" $maxWidth={1600} $margin="auto">
        <Box $lineHeight="0" $width="fit-content" $margin="auto">
          <Img desktopSrc="kakao" mobileSrc="moKakao" $width="100%" />
        </Box>
      </Section>
      {/* {alert && <Alert setAlert={setAlert} {...alert} />}
      {confirmModal && <ConfirmModal setConfirmModal={setConfirmModal} {...confirmModal} />} */}
      
      <Alert alert={alert} setAlert={setAlert} {...alert} />
      <Confirm confirm={confirm} setConfirm={setConfirm} {...confirm} />
    </S.Main>
  );
}
