/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable new-cap */

import React, { useRef, useEffect } from 'react';
import { Footer, Header } from 'layout';
import { I18nextProvider } from 'react-i18next';
import { theme, GlobalStyle } from 'styles';
import { ThemeProvider } from 'styled-components';
import { Landing } from 'pages';

import useScript from 'hooks/useScript';

import i18n from './i18n/i18n';

function App(props) {
  useScript('https://t1.kakaocdn.net/kakao_js_sdk/2.7.2/kakao.min.js', true, () => {
    if (!Kakao.isInitialized()) {
      Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
    }
  });


  const sectionRefs = useRef({});

  useEffect(() => {
    const { history } = window;
    history.scrollRestoration = 'manual';
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Header sectionRefs={sectionRefs} />
        <Landing sectionRefs={sectionRefs} />
        <Footer />
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
