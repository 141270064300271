import styled from 'styled-components';

export const Textarea = styled.textarea`
  box-sizing: border-box;
  min-height: 188px;
  width: 100%;
  resize: none;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${({ theme }) => theme.colors.gray50};
  border-radius: 8px;
  padding: 8px 16px;
  border: 1px solid transparent;
  transition: background-image 150ms;

  &::placeholder {
    color: ${({ theme }) => theme.colors.supportive};
  }

  &:focus {
    border: 1px solid transparent;
    background-image: linear-gradient(#fff, #fff), linear-gradient(to right, #2fffbd, #96bce0);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }

  @media ${({ theme }) => theme.device.mobile} {
    min-height: 56px;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  text-align: right;
  padding: 10px 10px 0;
  color: ${({ theme }) => theme.colors.placeholder};
`;
