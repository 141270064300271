import styled from 'styled-components';
import { RowBox } from 'styles';

export const InputBox = styled(RowBox)`
  position: relative;

  svg {
    position: absolute;
    right: 18px;
    top: 10px;
  }
`;

export const Input = styled.input`
  width: 100%;
  padding: 15px 60px 15px 16px;
  font-size: 16px;
  line-height: 1.5;
  background-color: ${({ theme }) => theme.colors.gray50};
  border: 1px solid transparent;
  border-radius: 8px;
  transition: background-image 150ms;
  box-sizing: border-box;

  ${({ $invalid, theme }) => $invalid && `border-color:${theme.colors.alert}`};

  &::placeholder {
    color: ${({ theme }) => theme.colors.supportive};
  }

  &:focus {
    border: 1px solid transparent;
    background-image: linear-gradient(#fff, #fff), linear-gradient(to right, #2fffbd, #96bce0);
    background-origin: border-box;
    background-clip: padding-box, border-box;
  }
`;
