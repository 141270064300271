import React from 'react';
import { Img } from 'components';
import { H2, Section } from 'styles';

export function Section4(props) {
  return (
    <Section $margin="auto" $width="fit-content" $maxWidth={1600} $padding="128px 40px" $mPadding="64px 0">
      <H2 $display="none">서비스</H2>
      <Img desktopSrc="main3" mobileSrc="moMain3" $width="100%" />
    </Section>
  );
}
