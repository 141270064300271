import styled from 'styled-components';
import check from 'assets/icons/check.svg';
import { space } from 'styles/css';

export const Label = styled.label`
  ${space}
  width:fit-content;
  display: flex;
  gap: 16px;
  cursor: pointer;
`;

export const Radio = styled.input.attrs({ type: 'radio' })`
  background-color: white;
  box-sizing: unset;
  margin: 2px;
  cursor: pointer;
  appearance: none;
  width: 18px;
  min-width: 18px;
  height: 18px;
  border: 1px solid ${({ theme }) => theme.colors.gray200};
  border-radius: 4px;
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 150ms ease;

  &:checked {
    border-color: ${({ theme }) => theme.colors.check};
    background-color: ${({ theme }) => theme.colors.check};
    background-image: url(${check});
  }
`;
