import React, { useEffect } from 'react';
import styled from 'styled-components';

export function Dim({ open, onClick, children }) {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [open]);

  return (
    <Container $open={open} onClick={onClick}>
      {children}
    </Container>
  );
}

const Container = styled.div`
  display: ${({ $open }) => ($open ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
`;
