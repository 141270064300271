import styled from 'styled-components';
import { Label } from 'styles';

export const InputLabel = styled(Label)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  @media ${({ theme }) => theme.device.mobile} {
    margin-bottom: 4px;
  }
`;
