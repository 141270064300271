import React from 'react';
import { Icon } from 'components';
import { H2, Section, Box, Text, Span, RowBox } from 'styles';

export function Section7({ onClick }) {
  return (
    <Section $padding="152px 40px" $mPadding="64px 20px" $maxWidth={1600} $margin="auto">
      <H2 $display="none">문의</H2>
      <Box $padding="64px" $mPadding="24px" $backgroundColor="gray50" $borderRadius={32}>
        <RowBox
          $justifyContent="space-between"
          $alignItems="center"
          $tFlexDirection="column-reverse"
          $tAlignItems="flex-start"
          $mGap={16}
        >
          <Text $type="44" $mType="16">
            복잡한 절차는 줄이고 편의성은 높인
            <br />
            <Span $type="b44" $mType="b20">
              N-Survey의 설문 서비스
            </Span>
            를 만나보세요!
          </Text>
          <Icon name="Logo" $mSvgWidth={64} $mSvgHeight={64} />
        </RowBox>
        <Box $marginTop={24}>
          <Text $type="b20" $mType="b14" $mColor="supportive">
            문의
          </Text>
          <RowBox $mFlexDirection="column" $alignItems="center" $mPadding="8px 0 0" $mAlignItems="flex-start">
            <Text $type="20" $mType="14">
              카카오톡 채널&nbsp;
            </Text>
            <RowBox $gap={16} $mGap="0" $alignItems="center">
              <Span $display="none" $mDisplay="block" />
              <Text $type="20" $mType="b16">
                @N-Survey
              </Text>
              <Icon name="BtnKakao" onClick={onClick} />
            </RowBox>
          </RowBox>
          <Text $type="20" $mType="14">
            이메일 문의&nbsp;
            <Span $display="none" $mDisplay="block" />
            <Span $type="20" $mType="b16">
              <a href="mailto:nsurvey@namowebiz.com">nsurvey@namowebiz.com</a>
            </Span>
          </Text>
        </Box>
      </Box>
    </Section>
  );
}
