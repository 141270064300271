import { css } from 'styled-components';
import { theme } from 'styles/Theme';

const line = css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
`;

const getType = ($type) => {
  const fontType = {
    l: 300,
    r: 400,
    m: 500,
    sb: 600,
    b: 700,
    xb: 800,
    bl: 900,
  };

  if (!$type) $type = 'r';

  let match = $type.match(/^(\D+)(\d+)?$/);

  if (!match || !match[1]) match = ['r', '', $type];

  const fontWeight = fontType[match[1]] || '';
  const fontSize = match[2] ? Number(match[2]) : 16;
  const lineHeight = fontSize >= 38 ? 1.2 : 1.5;

  return `font-weight: ${fontWeight}; font-size: ${fontSize}px; line-height: ${lineHeight};`;
};

const apply = ({ $type, $color, $wordBreak, $whiteSpace, $textAlign, $maxLine, $textDecoration }) => css`
  ${getType($type)}
  ${$color && `color: ${theme.colors[$color] || $color}`};
  ${$wordBreak && `word-break:${$wordBreak}`};
  ${$whiteSpace && `white-space:${$whiteSpace}`};
  ${$textAlign && `text-align:${$textAlign}`};
  ${$maxLine && `${line} -webkit-line-clamp: ${$maxLine}`};
  ${$textDecoration && `text-decoration:${$textDecoration}`}
`;

export const typography = css`
  ${apply};

  @media ${({ theme }) => theme.device.tablet} {
    ${({
      $type,
      $tType,
      $color,
      $tColor,
      $wordBreak,
      $tWordBreak,
      $textAlign,
      $tTextAlign,
      $whiteSpace,
      $tWhiteSpace,
      $maxLine,
      $tMaxLine,
    }) => css`
      ${apply({
        $type: $tType || $type,
        $color: $tColor || $color,
        $wordBreak: $tWordBreak || $wordBreak,
        $textAlign: $tTextAlign || $textAlign,
        $maxLine: $tMaxLine || $maxLine,
        $whiteSpace: $tWhiteSpace || $whiteSpace,
      })}
    `};
  }

  @media ${({ theme }) => theme.device.mobile} {
    ${({
      $type,
      $tType,
      $mType,
      $color,
      $tColor,
      $mColor,
      $wordBreak,
      $tWordBreak,
      $mWordBreak,
      $textAlign,
      $tTextAlign,
      $mTextAlign,
      $whiteSpace,
      $tWhiteSpace,
      $mWhiteSpace,
      $maxLine,
      $tMaxLine,
      $mMaxLine,
    }) => css`
      ${apply({
        $type: $mType || $tType || $type,
        $color: $mColor || $tColor || $color,
        $wordBreak: $mWordBreak || $tWordBreak || $wordBreak,
        $whiteSpace: $mWhiteSpace || $tWhiteSpace || $whiteSpace,
        $textAlign: $mTextAlign || $tTextAlign || $textAlign,
        $maxLine: $mMaxLine || $tMaxLine || $maxLine,
      })}
    `};
  }
`;
