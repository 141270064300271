import React from 'react';
import * as S from './CheckboxStyle';

export function Checkbox({ name, value, onChange, label, children, checked, ...props }) {
  const handleOnChange = (event) => {
    const { name, checked } = event.target;
    onChange(name, checked);
  };

  return (
    <S.Label htmlFor={name} {...props}>
      <S.Checkbox id={name} name={name} checked={checked || false} onChange={handleOnChange} />
      {children}
    </S.Label>
  );
}
