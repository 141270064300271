import styled from 'styled-components';
import { FlexListItem } from 'styles';

export const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100vw;
  background-color: white;
  border-bottom: 1px solid ${({ theme }) => theme.colors.fill005};
  z-index: 1;
`;

export const HeaderListItem = styled(FlexListItem)`
  transition: opacity 150ms;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.6;
    }
  }
`;
