import React from 'react';
import { Box, Text } from 'styles';
import * as S from './TextareaStyle';

export function FormikTextarea({ label, placeholder, name, value, onChange, onBlur, type, touched, invalid, ...props }) {
  return (
    <div>
      <Box $padding="8px 0" $mPadding="0">
        <S.Textarea
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={props.maxLength}
          $invalid={touched && invalid && invalid.length > 0}
        />
      </Box>
      {touched && invalid && invalid.length > 0 && (
        <Text $type="14" $color="alert">
          {invalid}
        </Text>
      )}
    </div>
  );
}
