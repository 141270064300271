import qs from 'qs';
import { isEmptyString } from '../utils/stringUtils';
import { ACCESS_TOKEN } from '../data/constants';

const get = async (urlPath = '', params = {}) => {
  const queryString = qs.stringify(params);
  const url = `${process.env.REACT_APP_API_DOMAIN}${urlPath}?${queryString}`;

  let authorizationValue = '';
  if (localStorage.getItem(ACCESS_TOKEN)) {
    authorizationValue = `${localStorage.getItem(ACCESS_TOKEN)}`;
  }

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorizationValue,
      },
    });

    const jsonResponse = await response.json();
    if (!jsonResponse) {
      return null;
    } else {
      return jsonResponse;
    }
  } catch (e) {
    console.error(e);
  }

  return null;
};

const post = async (urlPath = '', params = {}, headers = {}, body = {}) => {
  const queryString = qs.stringify(params);
  const url = `${process.env.REACT_APP_API_DOMAIN}${urlPath}?${queryString}`;
  let authorizationValue = '';
  if (localStorage.getItem(ACCESS_TOKEN)) {
    authorizationValue = `${localStorage.getItem(ACCESS_TOKEN)}`;
  }

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        ...headers,
        Authorization: authorizationValue,
      },
      body,
    });

    const jsonResponse = await response.json();

    if (!jsonResponse) {
      return null;
    } else {
      return jsonResponse;
    }
  } catch (e) {
    console.error(e);
  }

  return null;
};

const request = async (urlPath = '', params = {}, body = {}, method = 'POST') => {
  const queryString = qs.stringify(params);
  const url = `${process.env.REACT_APP_API_DOMAIN}${urlPath}?${queryString}`;
  let authorizationValue = '';
  if (localStorage.getItem(ACCESS_TOKEN)) {
    authorizationValue = `${localStorage.getItem(ACCESS_TOKEN)}`;
  }

  try {
    const response = await fetch(url, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: authorizationValue,
      },
      body: JSON.stringify(body),
    });

    const jsonResponse = await response.json();

    if (!jsonResponse) {
      return null;
    } else {
      return jsonResponse;
    }
  } catch (e) {
    console.error(e);
  }

  return null;
};

const requestMultipart = async (urlPath = '', params = {}, formData = null, method = 'POST') => {
  const queryString = qs.stringify(params);
  const url = `${process.env.REACT_APP_API_DOMAIN}${urlPath}?${queryString}`;
  let authorizationValue = '';
  if (localStorage.getItem(ACCESS_TOKEN)) {
    authorizationValue = `${localStorage.getItem(ACCESS_TOKEN)}`;
  }

  try {
    const response = await fetch(url, {
      method,
      headers: {
        Accept: 'application/json',
        Authorization: authorizationValue,
      },
      body: formData,
    });

    const jsonResponse = await response.json();

    if (!jsonResponse) {
      return null;
    } else {
      return jsonResponse;
    }
  } catch (e) {
    console.error(e);
  }

  return null;
};

const download = async (urlPath = '', params = {}, headers = {}, method = 'GET') => {
  const queryString = qs.stringify(params);
  const url = `${process.env.REACT_APP_API_DOMAIN}${urlPath}?${queryString}`;

  let authorizationValue = '';
  if (localStorage.getItem(ACCESS_TOKEN)) {
    authorizationValue = `${localStorage.getItem(ACCESS_TOKEN)}`;
  }

  try {
    const response = await fetch(url, {
      method,
      headers: {
        ...headers,
        Authorization: authorizationValue,
      },
    });

    if (!response) {
      return null;
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
  }

  return null;
};

const getImageUrlLinkByRelativePath = (relativePath) =>
  `${process.env.REACT_APP_API_DOMAIN}/common/loadFile?relativePath=${relativePath}`;

const getImageUrlLinkBySiteFileId = (siteFileId) =>
  `${process.env.REACT_APP_API_DOMAIN}/common/loadSiteFile?siteFileId=${siteFileId}`;

  const getSvgUrlLinkByRelativePath = (relativePath) =>
    `${process.env.REACT_APP_API_DOMAIN}/common/loadFileSvg?relativePath=${relativePath}`;

const getAPIUrlFromPath = (urlPath, params) => {
  if (isEmptyString(urlPath)) {
    return '';
  }
  const queryString = qs.stringify(params);
  return `${process.env.REACT_APP_API_DOMAIN}${urlPath}?${queryString}`;
};

const getAuthorizationHeader = (headers) => {
  let authorizationValue = '';
  if (localStorage.getItem(ACCESS_TOKEN)) {
    authorizationValue = `${localStorage.getItem(ACCESS_TOKEN)}`;
  }

  return {
    ...headers,
    Authorization: authorizationValue,
  };
};

export default {
  get,
  post,
  request,
  requestMultipart,
  download,
  getImageUrlLinkByRelativePath,
  getImageUrlLinkBySiteFileId,
  getSvgUrlLinkByRelativePath,
  getAPIUrlFromPath,
  getAuthorizationHeader,
};
