import styled, { css } from 'styled-components';
import { checkPixel } from 'utils';
import { Box } from 'styles';

export const IconBox = styled(Box)`
  line-height: 0;
  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          opacity: 0.6;
        }
      }
    `};

  .icon {
    ${(props) => props.$svgWidth && `width:${checkPixel(props.$svgWidth)}`};
    ${(props) => props.$svgHeight && `height:${checkPixel(props.$svgHeight)}`};
    ${(props) => props.$fill && `fill:${props.$fill}`};
    ${(props) => props.$stroke && `stroke:${props.$stroke}px`};
    & path {
      ${(props) => props.$pathFill && `fill:${props.$pathFill}`};
      ${(props) => props.$pathStroke && `stroke:${props.$pathStroke}px`};
    }
    @media ${({ theme }) => theme.device.tablet} {
      ${(props) => props.$tSvgWidth && `width:${checkPixel(props.$tSvgWidth)}`};
      ${(props) => props.$tSvgHeight && `height:${checkPixel(props.$tSvgHeight)}`};
      ${(props) => props.$tFill && `fill:${props.$tFill}`};
      ${(props) => props.$tStroke && `stroke:${props.$tStroke}px`};
      & path {
        ${(props) => props.$tPathFill && `fill:${props.$tPathFill}`};
        ${(props) => props.$tPathStroke && `stroke:${props.$tPathStroke}px`};
      }
    }
    @media ${({ theme }) => theme.device.mobile} {
      ${(props) => props.$mSvgWidth && `width:${checkPixel(props.$mSvgWidth)}`};
      ${(props) => props.$mSvgHeight && `height:${checkPixel(props.$mSvgHeight)}`};
      ${(props) => props.$mFill && `fill:${props.$mFill}`};
      ${(props) => props.$mStroke && `stroke:${props.$mStroke}px`};
      & path {
        ${(props) => props.$mPathFill && `fill:${props.$mPathFill}`};
        ${(props) => props.$mPathStroke && `stroke:${props.$mPathStroke}px`};
      }
    }
  }
`;
