import React from 'react';
import { Box, RowBox, Text } from 'styles';
import { Icon } from 'components/icon/Icon';
import { Button } from 'components/button/Button';
import * as S from './AlertStyle';
import { Dim } from '../Dim';

export function Alert({ alert, setAlert, ...props }) {
  return (
    <Dim open={alert}>
      <S.AlertContainer>
        <RowBox $justifyContent="space-between" $padding="0 10px 6px">
          <Text $type="b16">{props.title || '알림'}</Text>
          {props.close && <Icon name="Close" onClick={() => setAlert(false)} />}
        </RowBox>
        <Box $padding="10px" $marginBottom={10} $borderTop>
          <Text $type="m14">{props.content}</Text>
        </Box>
        <Button $type="modal" $float="right" $margin="0 10px" onClick={props.onClick}>
          확인
        </Button>
      </S.AlertContainer>
    </Dim>
  );
}
