import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import * as Icons from 'assets/icons';
import * as S from './IconStyle';

export function Icon({ moName, tabletName, name, onClick, svgProps, ...props }) {
  const theme = useTheme();

  const getIconName = () => {
    if (window.matchMedia(theme.device.mobile).matches) {
      return moName || name;
    } else if (window.matchMedia(theme.device.tablet).matches) {
      return tabletName || name;
    } else {
      return name;
    }
  };

  const [iconName, setIconName] = useState(getIconName);

  useEffect(() => {
    const updateIconName = () => setIconName(getIconName);
    window.addEventListener('resize', updateIconName);
    updateIconName();

    return () => window.removeEventListener('resize', updateIconName);
  }, [moName, tabletName, name, theme.device]);

  const IconComponent = Icons[iconName];

  if (!IconComponent) {
    return null;
  }

  return (
    <S.IconBox onClick={onClick} {...props}>
      <IconComponent className="icon" {...svgProps} />
    </S.IconBox>
  );
}
