import React from 'react';
import { Span } from 'styles';
import * as S from './InputLabelStyle';

export function InputLabel({ label, htmlFor, require, $invalid, ...props }) {
  return (
    <S.InputLabel $color={$invalid === 'error' ? 'alert' : 'normal'} $type="m" htmlFor={htmlFor} {...props}>
      {label}&nbsp;
      {require && (
        <Span $type="m12" $color={$invalid === 'error' ? 'alert' : 'normal'}>
          (필수)
        </Span>
      )}
    </S.InputLabel>
  );
}
