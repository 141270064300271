import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import * as Imgs from 'assets/images';
import * as S from './ImgStyle';

export function Img({ desktopSrc, tabletSrc, mobileSrc, alt, ...props }) {
  const theme = useTheme();

  useEffect(() => {
    const updateSrc = () => setSrc(getSrc);
    window.addEventListener('resize', updateSrc);
    updateSrc();

    return () => window.removeEventListener('resize', updateSrc);
  }, [desktopSrc, tabletSrc, mobileSrc, theme.device]);

  const getSrc = () => {
    if (window.matchMedia(theme.device.mobile).matches && mobileSrc) {
      return Imgs[mobileSrc];
    } else if (window.matchMedia(theme.device.tablet).matches && tabletSrc) {
      return Imgs[tabletSrc];
    } else {
      return Imgs[desktopSrc];
    }
  };

  const [src, setSrc] = useState(getSrc);

  return <S.ImgContainer src={src} alt={alt} {...props} />;
}
