// HEX 색상 RGBA로 변환
export const hexToRGB = (hex, opacity) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return (
    (result &&
      `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${opacity})`) ||
    hex
  );
};

// RGBA 색상 HEX로 변환
export const rgbaToHex = (rgba) => {
  if (!rgba) return '';

  const { r, g, b, a = 1 } = rgba;

  const red = r.toString(16).padStart(2, '0');
  const green = g.toString(16).padStart(2, '0');
  const blue = b.toString(16).padStart(2, '0');

  let alphaHex = '';
  if (a < 1) {
    const alpha = Math.round(a * 255);
    alphaHex = alpha.toString(16).padStart(2, '0');
  }

  return `#${red}${green}${blue}${alphaHex}`;
};

//  두 개의 색상 블렌딩
export const blendColors = (hexColor, blendColor, blendOpacity, opacity = 1) => {
  const rgbaColor = hexToRGB(hexColor, opacity);
  const rgbaBlendColor = hexToRGB(blendColor, blendOpacity);

  const [r1, g1, b1, a1] = rgbaColor
    .substring(5, rgbaColor.length - 1)
    .split(',')
    .map((val) => parseFloat(val));
  const [r2, g2, b2, a2] = rgbaBlendColor
    .substring(5, rgbaBlendColor.length - 1)
    .split(',')
    .map((val) => parseFloat(val));

  const r = Math.round(r1 * (1 - a2) + r2 * a2);
  const g = Math.round(g1 * (1 - a2) + g2 * a2);
  const b = Math.round(b1 * (1 - a2) + b2 * a2);
  const a = a1;

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};
